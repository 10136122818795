@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.cdnfonts.com/css/circular-std');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: 'Poppins', sans-serif;     
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.black {
  color: #000
}

.dspg-outline {
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: #F6AD2B;
}
       
.btn-grad {
  background: radial-gradient(118.57% 1536.71% at 50% 50%, #F6AD2B 0%, #EB1C24 50.31%, #233255 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  margin: 10px;
  padding: 10px 35px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}


.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-green-grad {
  background: radial-gradient(118.57% 1536.71% at 50% 50%, #108106 0%, #042601 48.96%, #233255 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  margin: 10px;
  padding: 10px 35px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 100% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}


.btn-green-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.carousel-indicators {
  display: none; /* Hide the carousel indicators */
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  background-color: black;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 24px;
}

@media screen and (max-width: 575px) {
  .carousel-control-prev-icon, .carousel-control-next-icon {
    display: none;
  }
}






.file-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-input input[type="file"] {
  display: none;
}

.file-icon {
  font-size: 6rem;
  color: #aaaaaa;
  cursor: pointer;
}

.file-input label {
  display: block;
  position: relative;
  width: 200px;
  height: 50px;
  border-radius: 25px;
  font-size: 1rem;
  background: linear-gradient(60deg, #dd5e89, #f7bb97);
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-out;
}

.file-input label:hover,
input:focus {
  transform: scale(1.02);
}

input:focus + label {
  outline: 1px solid #000;
  outline: -webkit-focus-ring-color auto 2px;
}

.link {
  color: #999999 !important;
}

/* a.active {
  color: #506ee4 !important;
} */
.sidebar-dropdown {
  background-color: #363e46;
  list-style-type: none;
}

.sidebar-dropdown li {
  margin-top: 0 !important;
}

.sidebar-dropdown li a {
  font-size: 12px !important;
}

.dspg-danger { 
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.dspg-dark {
color: #1b1e21;
background-color: #d6d8d9;
border-color: #c6c8ca;
}
.dspg-success {
color: #155724;
background-color: #d4edda;
border-color: #c3e6cb;
}
.dspg-warning {
color: #856404;
background-color: #fff3cd;
border-color: #ffeeba;
}
.dspg-info {
color: #0c5460;
background-color: #d1ecf1;
border-color: #bee5eb;
}
